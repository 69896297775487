import HttpCliente from "servicios/HttpCliente";
import axios from "axios";
import getResponseError from "utils/getResponseError";
import TokenService from "servicios/TokenService";

//FUNCION PARA NO PEDIR TOKEN DE SEGURIDAD EN LA PETICION
const instancia = axios.create();
instancia.CancelToken = axios.CancelToken;
instancia.isCancel = axios.isCancel;

export const UsuarioModel = {
  id: undefined,
  nombreCompleto: '',
  username: '',
  email: '',
  password: '',
  confirmarPassword: '',
};

/**
 * GET method - Obtiene información de tenant actual por token de usuario
 * @return {Promise} Promesa de obtención
 */
export const obtenerInfoTenantActual = () => {
  return new Promise((resolve, eject) => {
    HttpCliente.get("/catalog/tenants/info")
      .then((response) => {
        resolve([response, undefined, response.status]);
      })
      .catch((error) => {
        const err = getResponseError(error);
        resolve([undefined, err, error.response?.status]);
      });
  });
};

/**
 * POST method - Crear un nuevo usuario con usuario y contraseña
 * @param {UsuarioModel} usuario usuario
 * @return {Promise} Promesa de creación
 */
export const registrarUsuario = (usuario) => {
  return new Promise((resolve, eject) => {
    HttpCliente.post("/usuario/registrar", usuario)
      .then((response) => {
        resolve([response, undefined, response.status]);
      })
      .catch((error) => {
        const err = getResponseError(error);
        resolve([undefined, err, error.response?.status]);
      });
  });
};

/**
 * POST method - Crear un nuevo usuario por proveedor
 * @param {UsuarioModel} usuario usuario
 * @return {Promise} Promesa de creación
 */
export const registrarUsuarioPorProveedor = (usuario) => {
  return new Promise((resolve, eject) => {
    HttpCliente.post("/usuario/registrar/proveedor", usuario)
      .then((response) => {
        resolve([response, undefined, response.status]);
      })
      .catch((error) => {
        const err = getResponseError(error);
        resolve([undefined, err, error.response?.status]);
      });
  });
};

/**
 * GET method - Obtiene usuario actual por token
 * @param {any} dispatch Dispatch
 * @return {Promise} Promesa de obtención y actualización de dispatch
 */
export const obtenerUsuarioActual = (dispatch) => {
  return new Promise((resolve, eject) => {
    HttpCliente.get("/usuario")
      .then((response) => {
        if (dispatch !== undefined) {
          dispatch({
            type: "INICIAR_SESION",
            sesion: response.data,
            autenticado: true,
          });
        }
        resolve([response, undefined, response.status]);
      })
      .catch((error) => {
        const err = getResponseError(error);
        resolve([undefined, err, error.response?.status]);
      });
  });
};

/**
 * PUT method - Actualiza un usuario
 * @param {UsuarioModel} usuario usuario
 * @param {any} dispatch Dispatch
 * @return {Promise} Promesa de actualización
 */
export const actualizarUsuario = (usuario, dispatch) => {
  return new Promise((resolve, eject) => {
    HttpCliente.put("/usuario", usuario)
      .then((response) => {
        dispatch({
          type: "INICIAR_SESION",
          sesion: response.data,
          autenticado: true,
        });
        resolve([response, undefined, response.status]);
      })
      .catch(error => {
        const err = getResponseError(error);
        resolve([undefined, err, error.response?.status]);
      });
  });
};

/**
 * PUT method - Actualiza estado de un usuario
 * @param {string} email email
 * @param {boolean} habilitado habilitado
 * @param {boolean} convertirEnInformativo Si se debe convertir el usuario en tipo informativo
 * @param {boolean} convertirEnOperativo Si se debe convertir el usuario en tipo operativo
 * @param {boolean} usarProveedor Si se debe convertir el usuario en tipo operativo y se debe usar proveedor
 * @param {string} password Contraseña de cuenta en caso de que se deba convertir en operativo y sea sin proveedor
 * @return {Promise} Promesa de actualización
 */
export const actualizarEstadoUsuario = (email, nombreCompleto, habilitado, convertirEnInformativo, convertirEnOperativo, usarProveedor, password) => {
  return new Promise((resolve, eject) => {
    HttpCliente.put("/Usuario/estado", {
      email: email,
      nombreCompleto: nombreCompleto,
      habilitado: habilitado,
      convertirEnInformativo: convertirEnInformativo,
      convertirEnOperativo: convertirEnOperativo,
      usarProveedor: usarProveedor,
      password: password,
    })
      .then((response) => {
        resolve([response, undefined, response.status]);
      })
      .catch((error) => {
        const err = getResponseError(error);
        resolve([undefined, err, error.response?.status]);
      });
  });
};

/**
 * POST method - Inicia sesión con usuario y contraseña
 * @param {{Email: string, Password: string }} usuario credenciales de usuario
 * @param {any} dispatch Dispatch
 * @return {Promise} Promesa de identificación y actualización de dispatch
 */
export const loginUsuario = (usuario, dispatch) => {
  return new Promise((resolve, eject) => {
    instancia.post("/login/login", usuario).then(response => {
      if (response && response.data && !response.data.tenants) {
        TokenService.setLocalTokens(response.data.token, response.data.refreshToken);
        dispatch({
          type: "INICIAR_SESION",
          sesion: response.data,
          autenticado: true
        });
      }
      resolve([response, undefined, response.status]);
    }).catch(error => {
      const err = getResponseError(error);
      resolve([undefined, err, error.response?.status]);
    });
  });
};

/**
 * POST method - Inicia sesión con proveedor microsoft
 * @param {string} accessToken Access token
 * @param {string} idToken id token
 * @param {any} dispatch Dispatch
 * @return {Promise} Promesa de identificación y actualización de dispatch
 */
export const loginUsuarioConMicrosoft = (accessToken, idToken, dispatch, tenantName) => {
  return new Promise((resolve, eject) => {
    instancia.post("/login/login/microsoft", {
      idToken: idToken,
      accessToken: accessToken,
      tenantName: tenantName,
    }).then(response => {
      if (response && response.data && !response.data.tenants) {
        TokenService.setLocalTokens(response.data.token, response.data.refreshToken);
        dispatch({
          type: "INICIAR_SESION",
          sesion: response.data,
          autenticado: true
        });
      }
      resolve([response, undefined, response.status]);

    }).catch(error => {
      const err = getResponseError(error);
      resolve([undefined, err, error.response?.status]);
    });
  });
};

/**
 * POST method - Inicia sesión con proveedor google
 * @param {string} idToken id token
 * @param {any} dispatch Dispatch
 * @return {Promise} Promesa de identificación y actualización de dispatch
 */
export const loginUsuarioConGoogle = (idToken, dispatch, tenantName) => {
  return new Promise((resolve, eject) => {
    instancia.post("/login/login/google", {
      idToken: idToken,
      tenantName: tenantName,
    }).then(response => {
      if (response && response.data && !response.data.tenants) {
        TokenService.setLocalTokens(response.data.token, response.data.refreshToken);
        dispatch({
          type: "INICIAR_SESION",
          sesion: response.data,
          autenticado: true
        });
      }
      resolve([response, undefined, response.status]);
    }).catch(error => {
      const err = getResponseError(error);
      resolve([undefined, err, error.response?.status]);
    });
  });
};

/**
 * GET method - Obtiene una lista con todos usuarios
 * @param {'activos' | 'inactivos' | ''} filtro Filtro que permite obtener usuarios activos, inactivos o ambos
 * @return {Promise} Promesa de obtención
 */
export const obtenerUsuarios = (filtro = "") => {
  return new Promise((resolve, eject) => {
    HttpCliente.get(`/usuario/todos?Filtro=${filtro}`)
      .then((response) => {
        resolve([response, undefined, response.status]);
      })
      .catch((error) => {
        const err = getResponseError(error);
        resolve([undefined, err, error.response?.status]);
      });
  });
};

/**
 * GET method - Obtiene una lista con todos los usuarios asociado a un rol
 * @param {string} rol Nombre de rol
 * @param {'activos' | 'inactivos' | ''} filtro Filtro que permite obtener usuarios activos, inactivos o ambos
 * @return {Promise} Promesa de obtención
 */
export const obtenerUsuariosPorRol = (rol, filtro) => {
  return new Promise((resolve, eject) => {
    HttpCliente.get(`/usuario/rol/${rol}?Filtro=${filtro}`)
      .then((response) => {
        resolve([response, undefined, response.status]);
      })
      .catch((error) => {
        const err = getResponseError(error);
        resolve([undefined, err, error.response?.status]);
      });
  });
};

/**
 * GET method - Obtiene una lista con todos los usuarios asociado a un rol y empresa
 * @param {string} userName Nombre de usuario
 * @param {string} empresaId Id de empresa
 * @return {Promise} Promesa de obtención
 */
export const obtenerUsuariosPorRolEmpresa = (rol, empresaId) => {
  return new Promise((resolve, eject) => {
    
    HttpCliente.get(`/usuario/rol/${rol}/empresa/${empresaId === "todo" ? "" : empresaId}`)
      .then((response) => {
        resolve([response, undefined, response.status]);
      })
      .catch((error) => {
        const err = getResponseError(error);
        resolve([undefined, err, error.response?.status]);
      });
  });
};

/**
 * POST method - Obtiene una lista paginada con todos los usuarios filtradas por nombre completo
 * @param {number} pagina N° de página a recuperar
 * @param {number} cantElementos Cantidad de elementos por página
 * @param {string} usuario Nombre completo de usuario 
 * @param {string} orderBy Columna de orden
 * @param {string} orderDirection Dirección de orden asc o desc
 * @param {boolean} soloLectura Si solo filtra por usuarios informativos de lo contrario solo usuarios operativos
 * @return {Promise} Promesa de obtención
 */
export const paginacionUsuario = (pagina, cantElementos, usuario, orderBy = "", orderDirection = "", soloLectura = false) => {
  return new Promise((resolve, eject) => {
    HttpCliente.post('/usuario/report', {
      nombreCompleto: usuario,
      numeroPagina: pagina,
      cantidadElementos: cantElementos,
      orderBy: orderBy,
      orderDirection: orderDirection,
      soloLectura: soloLectura,
    })
      .then((response) => {
        resolve([response, undefined, response.status]);
      })
      .catch(error => {
        const err = getResponseError(error);
        resolve([undefined, err, error.response?.status]);
      });
  });
};

/**
 * GET method - Obtiene las notificaciones del usuario actual por token
 * @return {Promise} Promesa de obtención
 */
 export const obtenerNotificacionesUsuarioActual = () => {
  return new Promise((resolve, eject) => {
    HttpCliente.get("/usuario/misNotificaciones")
      .then((response) => {
        resolve([response, undefined, response.status]);
      })
      .catch((error) => {
        const err = getResponseError(error);
        resolve([undefined, err, error.response?.status]);
      });
  });
};

/**
 * POST method - Obtiene una lista paginada con todas las notificaciones de los usuarios filtradas por nombre completo o email
 * @param {number} pagina N° de página a recuperar
 * @param {number} cantElementos Cantidad de elementos por página
 * @param {string} usuario Nombre completo de usuario 
 * @return {Promise} Promesa de obtención
 */
 export const paginacionNotificacionesUsuario = (pagina, cantElementos, usuario) => {
  return new Promise((resolve, eject) => {
    HttpCliente.post('/usuario/notificaciones/report', {
      nombreCompleto: usuario,
      numeroPagina: pagina,
      cantidadElementos: cantElementos,
    })
      .then((response) => {
        resolve([response, undefined, response.status]);
      })
      .catch(error => {
        const err = getResponseError(error);
        resolve([undefined, err, error.response?.status]);
      });
  });
};

/**
 * PUT method - Marcar notificación como leida
 * @param {string} logCorreoEnviadoId Id de log correo
 * @return {Promise} Promesa con html notificación
 */
 export const marcarNotificacionComoLeida = (logCorreoEnviadoId) => {
  return new Promise((resolve, eject) => {
    HttpCliente.put(`/Usuario/misNotificaciones/${logCorreoEnviadoId}/leer`)
      .then((response) => {
        resolve([response, undefined, response.status]);
      })
      .catch((error) => {
        const err = getResponseError(error);
        resolve([undefined, err, error.response?.status]);
      });
  });
};

/**
 * GET method - Obtiene una lista con todos usuarios
 * @return {Promise} Promesa de obtención
 */
export const obtenerDuenosPolitica = () => {
  return new Promise((resolve, eject) => {
    HttpCliente.get(`/Usuario/duenosPolitica`)
      .then((response) => {
        resolve([response, undefined, response.status]);
      })
      .catch((error) => {
        const err = getResponseError(error);
        resolve([undefined, err, error.response?.status]);
      });
  });
};

/**
 * GET method - Obtiene firma de usuario actual por token
 * @return {Promise} Promesa de obtención
 */
export const obtenerFirmaUsuarioActual = () => {
  return new Promise((resolve, eject) => {
    HttpCliente.get("/usuario/firma")
      .then((response) => {
        resolve([response, undefined, response.status]);
      })
      .catch((error) => {
        const err = getResponseError(error);
        resolve([undefined, err, error.response?.status]);
      });
  });
};

/**
 * PUT method - Actualiza firma de usuario actual por token
 * @param {File} archivo Firma
 * @return {Promise} Promesa de actualización
 */
export const actualizarFirmaUsuarioActual = (archivo) => {
  return new Promise((resolve, eject) => {
    const formData = new FormData();
    formData.append('file', archivo);
    const config = {
      headers: {
          'content-type': 'multipart/form-data'
      }
    };

    HttpCliente.put("/usuario/firma", formData, config)
      .then((response) => {
        resolve([response, undefined, response.status]);
      })
      .catch(error => {
        const err = getResponseError(error);
        resolve([undefined, err, error.response?.status]);
      });
  });
};